<!-- BatchImportString -->
<template>
  <div v-if="isShow">
    <el-input
      v-model="textarea"
      type="textarea"
      :rows="6"
      placeholder="请输入内容"
    />
    <el-button-group>
      <el-button type="primary" @click="formatString">确定</el-button>
      <el-button type="info" @click="clearString">清除</el-button>
      <el-button type="danger" @click="closeThis">关闭</el-button>
    </el-button-group>
  </div>
</template>
<script>
export default {
  name: 'BatchImportString',
  props: {
    'batchImportToShow': {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  data() {
    return {
      isShow: 0,
      textarea: ''
    }
  },
  watch: {
    batchImportToShow(val) {
      this.isShow = val === 1 ? 1 : 0
    }
  },
  methods: {
    formatString() {
      const arr = Array.from(new Set(this.textarea.split(/[\s\n]/)))
      if (arr.indexOf('') > -1) {
        arr.splice(arr.indexOf(''), 1)
      }
      if (!arr.length) {
        this.$emit('stringIpt', '')
      } else {
        let res = ''
        for (let i = 0; i < arr.length; i++) {
          res += arr[i] + ','
        }
        res = res.substring(0, res.length - 1)
        this.$emit('stringIpt', res)
        this.textarea = ''
      }
    },
    clearString() {
      this.textarea = ''
    },
    closeThis() {
      this.$emit('stringIpt', '')
    }
  }

}
</script>
<style scoped>

</style>
